<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/accountList' }">帳號列表</el-breadcrumb-item>
                <el-breadcrumb-item>新增帳號</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="container">
            <div class="form-box">
                <div class="form-title">新增帳號</div>
                <el-form ref="formName"
                         :model="form"
                         :rules="rules"
                         label-width="120px"
                         label-position="left"
                         status-icon
                         @submit.prevent>
                    <el-form-item label="名稱"
                                  prop="name">
                        <el-input placeholder="最多10字元，不可有符號"
                                  v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="帳號"
                                  prop="account">
                        <el-input placeholder="英文+數字,6-18位數"
                                  v-model="form.account"></el-input>
                    </el-form-item>
                    <el-form-item label="密碼"
                                  prop="password">
                        <el-input placeholder="英文+數字,6-18位數"
                                  v-model="form.password"
                                  show-password></el-input>
                    </el-form-item>

                    <el-form-item label="敘述"
                                  prop="depiction">
                        <el-input type="textarea"
                                  placeholder="请输入内容"
                                  maxlength="20"
                                  show-word-limit
                                  v-model="form.depiction"></el-input>
                    </el-form-item>
                    <el-form-item label="角色權限"
                                  prop="auth_id">
                        <el-select v-model="form.auth_id"
                                   placeholder="請選擇權限"
                                   class="selectWidth">
                            <el-option v-for="(item,index) in authSelect"
                                       :label="item.text"
                                       :value="item.value"
                                       :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <div class="submit-btn">
                        <el-button type="primary"
                                   @click="onSubmit('formName')">確認</el-button>
                        <el-button @click="resetForm">重填</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { adminUsersCreateInfo, adminUsersCreate } from '../../../api/index'
export default {
    name: 'createAccount',
    data() {
        return {
            form: {},
            authSelect: [], //帳號權限
            rules: {
                name: [
                    {
                        required: true,
                        message: '請輸入名稱',
                        trigger: 'blur',
                    },
                ],
                account: [
                    {
                        required: true,
                        message: '請輸入帳號',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '請輸入密碼',
                        trigger: 'blur',
                    },
                ],
                auth_id: [
                    {
                        required: true,
                        message: '請選擇權限',
                        trigger: 'change',
                    },
                ],
            },
        }
    },
    created() {},
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            adminUsersCreateInfo().then((res) => {
                if (res.code == 300) {
                    this.authSelect = res.data.auth_select_list
                }
            })
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    adminUsersCreate(this.form)
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message.success('提交成功！')
                                this.$router.push({ path: 'accountList' })
                            } else if (res.code == 500) {
                                this.$message.error(res.message)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            this.$message.error('失敗')
                        })
                } else {
                    this.$message.error('失敗')
                    return false
                }
            })
        },
        resetForm() {
            this.form = []
        },
    },
}
</script>

<style lang="scss" scoped>
.form-box {
    margin: auto;
    width: 100%;
}
.form-title {
    text-align: center;
    font-size: 18px;
    padding-bottom: 20px;
}
.submit-btn {
    justify-content: center;
    display: flex;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .form-box {
        width: 600px;
    }
}
</style>
 <style lang="scss">
.el-textarea__inner {
    min-height: 80px !important;
}
</style>